<template>
  <div class="login-form">
    <div class="login-form-right">
      <div class="title">{{getTitle()}}</div>
      <a-divider style="margin-bottom: 20px">用户登录</a-divider>
      <login-form class="m-b-12"></login-form>
    </div>
  </div>
</template>

<script>

  import LoginForm from './LoginForm'
  import config from '../../config/defaultSettings'
  import serverUrl from '@/api/service.info'

  export default {
    data () {
      return { config: config }
    },
    components: {
      LoginForm
    },
    methods: {
      getTitle() {
        if (serverUrl.indexOf("znqyapi") > -1) {
          return "中宁县" +  config.systemInfo.systemName
        } else if (serverUrl.indexOf("127.0.0.1") > -1) {
          return "兴庆区" +  config.systemInfo.systemName
        } else {
          return config.systemInfo.systemName
        }
      }
    }
  }
</script>